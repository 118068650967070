<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-container
      v-show="verifiedOobCode"
      class="h-full flex justify-center items-center"
    >
      <div class="flex flex-col">
        <!-- メッセージ -->
        <div class="mb-4">新しいパスワードを入力してください。</div>

        <!-- 新しいパスワード -->
        <div>
          <validation-provider
            v-slot="{ errors }"
            name="新しいパスワード"
            rules="required|password"
          >
            <v-text-field
              v-model="newPassword"
              label="新しいパスワード"
              type="password"
              outlined
              dense
              :error-messages="errors"
            >
            </v-text-field>
          </validation-provider>
        </div>

        <!-- 新しいパスワード（確認用） -->
        <div>
          <validation-provider
            v-slot="{ errors }"
            name="新しいパスワード（確認用）"
            :rules="`required|password_confirm:${newPassword}`"
          >
            <v-text-field
              v-model="newPasswordConfirm"
              label="新しいパスワード（確認用）"
              type="password"
              outlined
              dense
              :error-messages="errors"
            >
            </v-text-field>
          </validation-provider>
        </div>

        <!-- 設定ボタン -->
        <v-btn color="primary" elevation="0" block :disabled="invalid" @click="settingNewPassword">
          設定
        </v-btn>
      </div>
    </v-container>
  </validation-observer>
</template>

<script>
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
export default {
  name: "NewPasswordSetting",
  components: {},

  data: () => ({
    // oobCode
    oobCode: "",

    // oobCode を検証済みか
    verifiedOobCode: false,

    // 新しいパスワード
    newPassword: "",

    // 新しいパスワード（確認用）
    newPasswordConfirm: "",
  }),
  computed: {},
  watch: {},
  mounted() {
    // URL から oobCode を抽出
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.slice(hash.indexOf("?") + 1));
    this.oobCode = params.get("oobCode");

    // oobCode の検証
    if (!this.oobCode) {
      this.$router.push("/").catch(() => {});
    }

    verifyPasswordResetCode(getAuth(), this.oobCode)
      .then(() => {
        this.verifiedOobCode = true;
      })
      .catch((error) => {
        // TODO: エラーハンドリング
        console.log(error);
        this.$router.push("/").catch(() => {});
      });
  },
  methods: {
    // 新しいパスワードを設定
    settingNewPassword() {
      // TODO: パスワードポリシーによるバリデーションチェック

      confirmPasswordReset(getAuth(), this.oobCode, this.newPassword)
        .then(() => {
          // TODO: トースト表示
          console.log("パスワードを更新しました");
          this.$router.push("/").catch(() => {});
        })
        .catch((error) => {
          // TODO: エラーハンドリング
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
