const getters = {};

const getDefaultState = () => ({
  // 自身のユーザー情報
  selfUser: null,

  // 家族情報
  families: [],

  // 医師情報
  doctors: [],
});

const state = getDefaultState();

const mutations = {
  // 自身のユーザー情報を設定
  setSelfUser: (state, { uid, selfUser }) => {
    state.selfUser = { uid, ...selfUser };
  },

  // 家族情報を追加 or 更新
  setFamily: (state, { uid, family }) => {
    const index = state.families.findIndex((f) => f.uid === uid);

    if (index !== -1) {
      state.families.splice(index, 1, { uid, ...state.families[index], ...family });
    } else {
      state.families.push({ uid, ...family });
    }
  },

  // 医師情報を追加 or 更新
  setDoctor: (state, { uid, doctor }) => {
    const index = state.doctors.findIndex((f) => f.uid === uid);

    if (index !== -1) {
      state.doctors.splice(index, 1, { uid, ...state.doctors[index], ...doctor });
    } else {
      state.doctors.push({ uid, ...doctor });
    }
  },

  removeUser: (state, { uid }) => {
    const doctorIndex = state.doctors.findIndex((f) => f.uid === uid);
    if (doctorIndex !== -1) {
      state.doctors.splice(doctorIndex, 1);
      return;
    }

    const familyIndex = state.families.findIndex((f) => f.uid === uid);
    if (familyIndex !== -1) {
      state.families.splice(familyIndex, 1);
      return;
    }
  },

  // stateの初期化
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  setSelfUser: ({ commit }, { uid, selfUser }) => {
    commit("setSelfUser", { uid, selfUser });
  },
  setFamily: ({ commit }, { uid, family }) => {
    commit("setFamily", { uid, family });
  },
  setDoctor: ({ commit }, { uid, doctor }) => {
    commit("setDoctor", { uid, doctor });
  },
  removeUser: ({ commit }, { uid }) => {
    commit("removeUser", { uid });
  },
  resetState: ({ commit }) => {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
