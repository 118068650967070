const getters = {};

const getDefaultState = () => ({
  // 購読解除一覧
  unsubscribes: [],
  // ユーザー購読解除一覧
  userUnsubscribes: [],
});

const state = getDefaultState();

const mutations = {
  // 購読解除を追加
  addUnsubscribe: (state, { unsubscribe }) => {
    state.unsubscribes.push(unsubscribe);
  },
  // ユーザーに関する購読解除を追加
  addUserUnsubscribe: (state, { uid, unsubscribe }) => {
    state.userUnsubscribes.push({ uid, unsubscribe });
  },
  // 購読解除したユーザーに関する購読解除処理を除去
  removeUserUnsubscribe: (state, { uid }) => {
    const index = state.userUnsubscribes.findIndex((unsubscribe) => unsubscribe.uid === uid);

    if (index !== -1) {
      state.userUnsubscribes.splice(index, 1);
    }
  },

  // stateの初期化
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  addUnsubscribe: ({ commit }, { unsubscribe }) => {
    commit("addUnsubscribe", { unsubscribe });
  },
  addUserUnsubscribe: ({ commit }, { uid, unsubscribe }) => {
    commit("addUserUnsubscribe", { uid, unsubscribe });
  },
  removeUserUnsubscribe: ({ commit }, { uid }) => {
    commit("removeUserUnsubscribe", { uid });
  },
  resetState: ({ commit }) => {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
