import Vue from "vue";
import Vuex from "vuex";
import api from "./modules/api";
import chat from "./modules/chat";
import snackbar from "./modules/snackbar";
import unsubscribe from "./modules/unsubscribe";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    api,
    user,
    chat,
    snackbar,
    unsubscribe,
  },
  actions: {
    resetState({ dispatch }) {
      return Promise.all([
        dispatch("user/resetState"),
        dispatch("chat/resetState"),
        dispatch("unsubscribe/resetState"),
      ]);
    },
  },
});
