import Chat from "@/components/pages/Chat";
import ChatGroupList from "@/components/pages/ChatGroupList";
import ChatGroupMembers from "@/components/pages/ChatGroupMembers";
import Graph from "@/components/pages/Graph";
import Input from "@/components/pages/Input";
import List from "@/components/pages/List";
import Login from "@/components/pages/Login";
import NewPasswordSetting from "@/components/pages/NewPasswordSetting";
import PasswordChange from "@/components/pages/PasswordChange";
import PasswordResetting from "@/components/pages/PasswordResetting";
import Setting from "@/components/pages/Setting";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/login",
    component: Login,
  },
  {
    path: "/chat-group-list",
    name: "/chat-group-list",
    component: ChatGroupList,
    meta: { requiresAuth: true, menuId: "chat" },
  },
  {
    path: "/chat/:groupId",
    name: "/chat",
    component: Chat,
    props: (route) => ({ groupId: route.params.groupId }),
    meta: { requiresAuth: true, menuId: "chat" },
  },
  {
    path: "/chat-group-members",
    name: "/chat-group-members",
    component: ChatGroupMembers,
    props: (route) => ({ groupId: route.params.groupId }),
    meta: { requiresAuth: true, menuId: "chat" },
  },
  {
    path: "/input",
    name: "/input",
    component: Input,
    meta: { requiresAuth: true, menuId: "input" },
  },
  {
    path: "/list",
    name: "/list",
    component: List,
    meta: { requiresAuth: true, menuId: "list" },
  },
  {
    path: "/graph",
    name: "/graph",
    component: Graph,
    meta: { requiresAuth: true, menuId: "graph" },
  },
  {
    path: "/setting",
    name: "/setting",
    component: Setting,
    meta: { requiresAuth: true, menuId: "setting" },
  },
  {
    path: "/password-resetting",
    name: "/password-resetting",
    component: PasswordResetting,
  },
  {
    path: "/new-password-setting",
    name: "/new-password-setting",
    component: NewPasswordSetting,
  },

  {
    path: "/password-change",
    name: "/password-change",
    component: PasswordChange,
    meta: { requiresAuth: true, menuId: "setting" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
