<template>
  <v-container class="px-0">
    <div class="my-2"></div>

    <!-- 各種設定 -->
    <div v-for="(menu, index) in menus" :key="index" class="cursor-pointer" @click="action(index)">
      <div class="flex px-4">
        <div class="flex justify-between cursor-pointer hover:opacity-85">
          <div>{{ menu }}</div>
          <div>
            <v-icon> mdi-chevron-right </v-icon>
          </div>
        </div>
      </div>

      <v-divider class="my-4 px-0"></v-divider>
    </div>

    <!-- ログアウト確認ダイアログ -->
    <v-dialog v-model="logoutConfirmDialog.show" max-width="480">
      <confirm-dialog
        v-if="logoutConfirmDialog.show"
        title="ログアウトしますか？"
        :message="`サービスをご利用になるには\n再度ログインしていただく必要があります`"
        @ok="logout"
        @cancel="logoutConfirmDialog.show = false"
      ></confirm-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import Confirm from "@/components/dialogs/Confirm";
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import fcmTokenMixin from "@/mixins/fcmTokenMixin";
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "Setting",
  mixins: [errorHandlerMixin(), fcmTokenMixin()],
  components: { ConfirmDialog: Confirm },
  data: () => ({
    menus: [
      // "文字サイズ変更  ",
      "メールアドレス変更",
      "パスワード変更",
      "ログアウト",
    ],

    // ログアウト確認ダイアログ
    logoutConfirmDialog: {
      show: false,
    },
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    action(index) {
      switch (this.menus[index]) {
        case "ログアウト":
          this.logoutConfirmDialog.show = true;
          break;

        case "パスワード変更":
          this.$router.push("/password-change").catch(() => {});
          break;

        default:
          alert("TODO");
      }
    },
    async logout() {
      try {
        const auth = getAuth();
        const uid = auth.currentUser?.uid;
        if (uid) {
          // FCMトークンの削除処理
          await this.deleteFcmToken(uid);

          // ログアウト処理
          await signOut(auth);

          // ネイティブアプリの場合、通知とバッジ(iOSのみ)を削除する
          if (window.navigator.userAgent.indexOf("WV_app") != -1) {
            await window.flutter_inappwebview.callHandler("resetNotificationAndBadge");
          }
        }
        this.$router.push("/").catch(() => {});
      } catch (e) {
        // TODO: エラーハンドリング
        this.showError(MESSAGES.ERRORS.UNEXPECTED);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
