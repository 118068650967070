<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-container class="class=px-0">
      <!-- メッセージ -->
      <div class="mb-4">現在のパスワードと、新しいパスワードを入力してください。</div>

      <!-- 現在のパスワード -->
      <div>
        <validation-provider name="現在のパスワード" rules="required">
          <v-text-field
            v-model="currentPassword"
            label="現在のパスワード"
            type="password"
            outlined
            dense
          >
          </v-text-field>
        </validation-provider>
      </div>

      <!-- 新しいパスワード -->
      <div>
        <validation-provider v-slot="{ errors }" name="新しいパスワード" rules="required|password">
          <v-text-field
            v-model="newPassword"
            label="新しいパスワード"
            type="password"
            outlined
            dense
            :error-messages="errors"
          >
          </v-text-field>
        </validation-provider>
      </div>

      <!-- 新しいパスワード（確認用） -->
      <div>
        <validation-provider
          v-slot="{ errors }"
          name="新しいパスワード（確認用）"
          :rules="`required|password_confirm:${newPassword}`"
        >
          <v-text-field
            v-model="newPasswordConfirm"
            label="新しいパスワード（確認用）"
            type="password"
            outlined
            dense
            :error-messages="errors"
          >
          </v-text-field>
        </validation-provider>
      </div>

      <!-- 変更ボタン -->
      <v-btn color="primary" elevation="0" block :disabled="invalid" @click="passwordChange">
        変更
      </v-btn>
    </v-container>
  </validation-observer>
</template>

<script>
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { mapActions } from "vuex";
export default {
  name: "PasswordChange",
  mixins: [errorHandlerMixin()],
  data: () => ({
    // 現在のパスワード
    currentPassword: "",
    // 新しいパスワード
    newPassword: "",
    // 新しいパスワード（確認用）
    newPasswordConfirm: "",
  }),
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    passwordChange() {
      this.incrementRunningApiCount();

      // パスワード変更前に再認証が必要なため、再認証を行う
      const auth = getAuth();
      const user = auth.currentUser;

      const credential = EmailAuthProvider.credential(user.email, this.currentPassword);

      reauthenticateWithCredential(user, credential)
        .then(() => {
          updatePassword(user, this.newPassword, this.newPasswordConfirm)
            .then(() => {
              this.openSnackbar({ text: "パスワードを変更しました" });

              this.decrementRunningApiCount();

              this.$router.push("/setting").catch(() => {});
            })
            .catch(() => {
              this.showError(MESSAGES.ERRORS.PASSWORD_CHANGE);
            });
        })
        .catch(() => {
          this.showError(MESSAGES.ERRORS.PASSWORD_CHANGE);
        });
    },
  },
};
</script>
