const getters = {};

const state = {
  // 実行中の API数
  runningApiCount: 0,
};

const mutations = {
  // 実行中の API数をインクリメント
  incrementRunningApiCount: (state) => {
    state.runningApiCount++;
  },

  // 実行中の API数をデクリメント
  decrementRunningApiCount: () => {
    state.runningApiCount--;
  },

  // 実行中の API数をリセット
  resetRunningApiCount: () => {
    state.runningApiCount = 0;
  },
};

const actions = {
  incrementRunningApiCount: ({ commit }) => {
    commit("incrementRunningApiCount");
  },
  decrementRunningApiCount: ({ commit }) => {
    commit("decrementRunningApiCount");
  },
  resetRunningApiCount: ({ commit }) => {
    commit("resetRunningApiCount");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
