<template>
  <v-container class="px-0 pt-0">
    <!-- 年月 -->
    <div class="px-4 pt-4 mb-4 white flex justify-between items-center sticky top-12 z-10">
      <month-picker v-model="date" :maxMonth="maxMonth" :minMonth="minMonth"></month-picker>
    </div>

    <!-- 患者選択 -->
    <div v-if="isPatient == false || patients.length >= 2" class="flex justify-end px-2">
      <div class="w-48">
        <v-select
          v-model="selectedPatientUid"
          :items="patients"
          item-value="uid"
          item-text="name"
          :menu-props="{ offsetY: true }"
          outlined
          dense
        ></v-select>
      </div>
    </div>

    <div class="space-y-8 px-2">
      <v-card v-for="(record, index) in records" :key="index" class="space-y-2 p-2" outlined>
        <!-- 日付 -->
        <div class="h-0 text-center">
          <v-chip class="absolute -top-5 pointer-events-none" small outlined>
            <span class="mr-1">
              {{ moment(record.date).format("M/D") }}
            </span>
            (
            <span
              :class="{
                'text-red-500': moment(record.date).day() == 0,
                'text-blue-500': moment(record.date).day() == 6,
              }"
            >
              {{ moment(record.date).format("ddd") }}
            </span>
            )
          </v-chip>

          <v-btn
            v-if="selectedSelfUser"
            class="absolute top-1 right-1"
            icon
            @click="onClickRecord(record.date)"
          >
            <v-icon @click="onClickRecord(record.date)">mdi-note-edit-outline</v-icon>
          </v-btn>
        </div>

        <!-- 記録 -->
        <div class="flex items-center pl-2">
          <div class="flex-1 space-y-4">
            <!-- 体重 -->
            <div class="space-y-1">
              <div class="text-xs">体重</div>
              <div class="flex items-end space-x-1 pl-2">
                <div v-if="record?.weight" class="text-3xl text-primary">{{ record?.weight }}</div>
                <div v-else class="text-3xl text-gray-400">---.--</div>
                <div>kg</div>
              </div>
            </div>

            <!-- 血圧・脈拍 -->
            <div class="space-y-1">
              <!-- 朝 -->
              <div class="text-xs">血圧・脈拍</div>
              <div
                v-for="(prop, index) in ['morning', 'night']"
                :key="prop"
                class="flex items-end space-x-1 pl-2"
              >
                <div class="flex items-center space-x-4">
                  <v-icon :class="{ '-rotate-45': index }" :color="index ? 'indigo' : 'orange'">
                    {{ index ? "mdi-moon-waning-crescent" : "mdi-white-balance-sunny" }}
                  </v-icon>

                  <!-- 血圧 -->
                  <div>
                    <!-- 最高 -->
                    <span v-if="record?.[prop]?.systolicPressure" class="text-3xl text-red-500">
                      {{ record?.[prop]?.systolicPressure }}
                    </span>
                    <span v-else class="text-3xl text-gray-400"> --- </span>

                    <span class="text-3xl text-gray-300"> / </span>

                    <!-- 最低 -->
                    <span v-if="record?.[prop]?.diastolicPressure" class="text-3xl text-blue-500">
                      {{ record?.[prop]?.diastolicPressure }}
                    </span>
                    <span v-else class="text-3xl text-gray-400"> --- </span>

                    <span class="text-xs">mmHg</span>
                  </div>

                  <!-- 脈拍 -->
                  <div>
                    <span v-if="record?.[prop]?.heartRate" class="text-3xl text-primary">
                      {{ record?.[prop]?.heartRate }}
                    </span>
                    <span v-else class="text-3xl text-gray-400"> --- </span>
                    <span class="text-xs">拍/分</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- 服薬チェック -->
            <div class="space-y-2">
              <div class="text-xs">服薬チェック</div>
              <div class="flex flex-wrap gap-2 pl-2">
                <div
                  v-for="{ label, value } in [
                    { label: '朝', value: 'morning' },
                    { label: '昼', value: 'evening' },
                    { label: '晩', value: 'noon' },
                    { label: '寝る前', value: 'bedtime' },
                  ]"
                  :key="value"
                >
                  <v-chip
                    v-if="isSelectedMedicationCheck(record?.medicationChecks, value)"
                    class="pointer-events-none"
                    outlined
                  >
                    <v-icon color="teal" small left>mdi-checkbox-blank-circle-outline</v-icon>
                    {{ label }}
                  </v-chip>
                  <v-chip v-else outlined disabled>
                    {{ label }}
                  </v-chip>
                </div>
              </div>
            </div>

            <!-- 自覚症状 -->
            <div class="space-y-2">
              <div class="text-xs">自覚症状</div>
              <div class="flex flex-wrap gap-2 pl-2">
                <div
                  v-for="symptoms in [
                    { prop: 'shortnessOfBreath', label: '息切れ' },
                    { prop: 'swelling', label: 'むくみ' },
                    { prop: 'tiredness', label: '疲れやすい' },
                    { prop: 'lossOfAppetite', label: '食欲低下' },
                    { prop: 'insomnia', label: '不眠' },
                  ]"
                  :key="symptoms.prop"
                >
                  <v-chip
                    class="pointer-events-none"
                    outlined
                    :disabled="!record?.symptoms?.[symptoms.prop]"
                  >
                    {{ symptoms.label }}
                  </v-chip>
                </div>
              </div>
            </div>

            <!-- 運動 -->
            <div class="space-y-2">
              <div class="text-xs">運動</div>
              <div class="flex flex-wrap gap-2 pl-2">
                <template v-if="record && record.exercise != null">
                  <!-- した -->
                  <v-chip v-if="record.exercise" class="pointer-events-none" outlined>
                    <v-icon color="teal" small left>mdi-checkbox-blank-circle-outline</v-icon>
                    した
                  </v-chip>

                  <!-- してない -->
                  <v-chip v-else class="pointer-events-none" outlined>
                    <v-icon color="error" small left>mdi-close</v-icon>
                    してない
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip outlined disabled> 未入力 </v-chip>
                </template>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import MonthPicker from "@/components/molecules/MonthPicker";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "List",
  components: { MonthPicker },
  data: () => ({
    // 選択されている患者のUID
    selectedPatientUid: null,

    // 記録
    records: [],

    // 記録一覧グラフの日付
    date: moment().format("YYYYMMDD"),

    // 選択できる月の上限
    maxMonth: moment().format("YYYYMM"),
  }),
  computed: {
    // 自身が患者かどうか
    isPatient() {
      if (this.$store.state.user.selfUser == null) {
        return null;
      }
      return this.$store.state.user.selfUser?.type == "patient";
    },

    // 自身が選択されているか
    selectedSelfUser() {
      return this.$store.state.user.selfUser?.uid == this.selectedPatientUid;
    },

    // 患者情報
    patients() {
      // 家族情報から患者を抽出
      const patients = this.$store.state.user.families.filter((family) => family.type == "patient");

      // ログインユーザーが患者の場合は自身を先頭に追加
      if (this.isPatient) {
        patients.unshift({ ...this.$store.state.user.selfUser });
      }

      return patients.map((patient) => {
        return { ...patient, name: `${patient.lastName} ${patient.firstName}` };
      });
    },

    // 選択できる月の下限
    minMonth() {
      // 選択中患者UIDが未セットの場合、クライアント側時刻を返しておく
      if (!this.selectedPatientUid) {
        return moment().format("YYYYMM");
      }

      const { created } = this.$store.state.user.selfUser;
      const timestamp = created.seconds * 1000;
      return moment(timestamp).format("YYYYMM");
    },
  },
  watch: {
    date() {
      this.getRecords();
    },
    patients() {
      // 患者未選択の場合は患者情報の先頭を選択
      // NOTE: 患者情報取得は非同期処理のため mounted で間に合わない場合は watch で設定する
      if (this.selectedPatientUid == null && this.patients.length) {
        this.selectedPatientUid = this.patients[0].uid;
      }
    },
    selectedPatientUid() {
      this.getRecords();
    },
  },
  mounted() {
    // ログインユーザーが患者の場合は自身の UID を設定
    if (this.isPatient) {
      this.selectedPatientUid = this.$store.state.user.selfUser?.uid;
    } else {
      // 患者未選択の場合は患者情報の先頭を選択
      // NOTE: 患者情報取得は非同期処理のため mounted で間に合わない場合は watch で設定する
      if (this.patients.length) {
        this.selectedPatientUid = this.patients[0].uid;
      }
    }
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // データ取得
    async getRecords() {
      // 選択中患者UIDが未セットの場合、処理を行わない
      if (!this.selectedPatientUid) {
        return;
      }

      this.incrementRunningApiCount();

      this.records = [];

      // 選択された月
      const selectedDate = moment(this.date).format("YYYYMM");
      const db = await getFirestore();
      const getRecords = await getDoc(
        doc(db, "users", this.selectedPatientUid, "records", selectedDate)
      );

      // システム日付(クライアント側取得)
      const systemDate = moment();
      const systemMonth = systemDate.format("YYYYMM");

      // システム日付の月を表示する場合はシステム日付までの表示
      const maxDisplayDate =
        systemMonth === selectedDate ? systemDate.get("date") : moment(this.date).daysInMonth();

      for (let i = 1; i <= maxDisplayDate; i++) {
        const dd = `${i}`.padStart(2, "0");
        const record = getRecords.data()?.[dd] || {};
        this.records.push({ date: `${selectedDate}${dd}`, ...record });
      }

      // 降順並びに
      this.records.reverse();

      this.decrementRunningApiCount();
    },
    // 記録クリック
    onClickRecord(date) {
      this.$router
        .push({
          name: "/input",
          query: { date: moment(date).format("YYYYMMDD") },
        })
        .catch(() => {});
    },

    // 服薬チェックが選択されているかどうか
    isSelectedMedicationCheck(medicationChecks = [], value) {
      return medicationChecks.findIndex((medicationCheck) => medicationCheck == value) != -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip.v-chip--outlined.v-chip.v-chip {
  background-color: white !important;
}
</style>
