<template>
  <validation-observer ref="observer" v-slot="{ invalid, dirty }">
    <v-container class="px-0 pt-0 space-y-2">
      <!-- 日付 -->
      <div class="px-4 pt-4 mb-4 white flex justify-between items-center sticky top-12 z-10">
        <!-- 前日へ -->
        <v-btn icon @click="onClickYesterday">
          <v-icon color="primary" large> mdi-chevron-left </v-icon>
        </v-btn>

        <!-- 日付 -->
        <div>
          {{ formatedDate }}
        </div>

        <!-- 翌日へ -->
        <v-btn icon :disabled="isToday" @click="onClickTomorrow">
          <v-icon color="primary" large> mdi-chevron-right </v-icon>
        </v-btn>
      </div>

      <!-- 体重 -->
      <v-card class="mx-2" elevation="0">
        <div class="flex justify-between p-4 bg-blue-50">
          <!-- ラベル・アイコン -->
          <div>
            <span class="text-xl tracking-widest text-blue-600"> 体重 </span>
            <div class="absolute top-0 left-4 rotate-12 opacity-10">
              <v-icon color="blue" size="80px">mdi-scale-bathroom </v-icon>
            </div>
          </div>

          <validation-provider class="w-40">
            <v-text-field
              v-model="weight"
              type="number"
              hide-spin-buttons
              flat
              solo
              :readonly="!isPatient"
              hide-details
              @keyup.enter="focusNextElement"
              @blur="
                () => {
                  weight = formatedInputValue(weight, 3, 2);
                }
              "
            >
              <template #append-outer>
                <div class="mt-3 w-10 text-sm text-gray-500 whitespace-nowrap">kg</div>
              </template>
            </v-text-field>
          </validation-provider>
        </div>
      </v-card>

      <!-- 血圧 -->
      <v-card
        v-for="({ label, prop }, index) in [
          { label: '朝', prop: 'morning' },
          { label: '夜', prop: 'night' },
        ]"
        :key="`blood-pressure-${index}`"
        class="mx-2"
        elevation="0"
      >
        <div
          class="flex flex-col p-4 space-y-2"
          :class="{
            'bg-orange-50': prop == 'morning',
            'bg-violet-50': prop == 'night',
          }"
        >
          <!-- ラベル・アイコン -->
          <div>
            <span
              class="text-xl tracking-widest"
              :class="{
                'text-orange-600': prop == 'morning',
                'text-violet-600': prop == 'night',
              }"
            >
              {{ label }}の血圧・脈拍
            </span>
            <div class="absolute top-2 left-2 opacity-10">
              <v-icon
                :class="{ '-rotate-45': prop == 'night' }"
                :color="prop == 'morning' ? 'orange' : 'indigo'"
                size="90px"
              >
                {{ prop == "morning" ? "mdi-white-balance-sunny" : "mdi-moon-waning-crescent" }}
              </v-icon>
            </div>
          </div>

          <!-- 最高血圧 -->
          <div class="pl-4 flex justify-between items-center">
            <div>最高血圧</div>
            <validation-provider class="w-40">
              <v-text-field
                v-model="$data[prop].systolicPressure"
                type="number"
                hide-spin-buttons
                flat
                solo
                :readonly="!isPatient"
                hide-details
                @keyup.enter="focusNextElement"
                @blur="
                  () => {
                    $data[prop].systolicPressure = formatedInputValue(
                      $data[prop].systolicPressure,
                      3
                    );
                  }
                "
              >
                <template #append-outer>
                  <div class="mt-3 w-10 text-sm whitespace-nowrap text-gray-500">mmHg</div>
                </template>
              </v-text-field>
            </validation-provider>
          </div>

          <!-- 最低血圧 -->
          <div class="pl-4 flex justify-between items-center">
            <div>最低血圧</div>
            <validation-provider class="w-40">
              <v-text-field
                v-model="$data[prop].diastolicPressure"
                type="number"
                hide-spin-buttons
                flat
                solo
                :readonly="!isPatient"
                hide-details
                @keyup.enter="focusNextElement"
                @blur="
                  () => {
                    $data[prop].diastolicPressure = formatedInputValue(
                      $data[prop].diastolicPressure,
                      3
                    );
                  }
                "
              >
                <template #append-outer>
                  <div class="mt-3 w-10 text-sm whitespace-nowrap text-gray-500">mmHg</div>
                </template>
              </v-text-field>
            </validation-provider>
          </div>

          <!-- 脈拍 -->
          <div class="pl-4 flex justify-between items-center">
            <div>脈拍</div>
            <validation-provider class="w-40">
              <v-text-field
                v-model="$data[prop].heartRate"
                type="number"
                hide-spin-buttons
                flat
                solo
                :readonly="!isPatient"
                hide-details
                @keyup.enter="focusNextElement"
                @blur="
                  () => {
                    $data[prop].heartRate = formatedInputValue($data[prop].heartRate, 3);
                  }
                "
              >
                <template #append-outer>
                  <div class="mt-3 w-10 text-sm whitespace-nowrap text-gray-500">拍/分</div>
                </template>
              </v-text-field>
            </validation-provider>
          </div>
        </div>
      </v-card>

      <!-- 服薬チェック -->
      <v-card class="mx-2" elevation="0">
        <div class="p-4 bg-green-50 space-y-2">
          <!-- ラベル・アイコン -->
          <div>
            <span class="text-xl tracking-widest text-green-600"> 服薬チェック </span>
            <div class="absolute top-0 left-0 -rotate-12 opacity-10">
              <v-icon color="teal" size="90px"> mdi-pill </v-icon>
            </div>
          </div>

          <!-- 服薬チェック -->
          <validation-provider class="pl-4 flex justify-center">
            <v-btn-toggle
              v-model="medicationChecks"
              :class="{ 'pointer-events-none': !isPatient }"
              color="transparent"
              multiple
            >
              <v-btn
                v-for="{ label, value } in [
                  { label: '朝', value: 'morning' },
                  { label: '昼', value: 'evening' },
                  { label: '晩', value: 'noon' },
                  { label: '寝る前', value: 'bedtime' },
                ]"
                :key="value"
                :value="value"
                class="flex-1"
                height="80"
                width="100"
              >
                <span class="text-gray-800">
                  {{ label }}
                </span>
                <v-icon
                  v-if="isSelectedMedicationCheck(value)"
                  color="teal"
                  class="!absolute !text-7xl"
                >
                  mdi-checkbox-blank-circle-outline
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </validation-provider>
        </div>
      </v-card>

      <!-- 自覚症状 -->
      <v-card elevation="0">
        <div class="p-4 space-y-2">
          <!-- ラベル・アイコン -->
          <div>
            <span class="text-xl tracking-widest text-rose-600"> 自覚症状 </span>
            <div class="absolute top-2 left-2 opacity-10">
              <v-icon color="red" size="90px"> mdi-alert </v-icon>
            </div>
          </div>

          <!-- 自覚症状 -->
          <div>
            <div
              v-for="({ label, choices, prop }, index) in symptomsLabels"
              :key="`subjective-symptoms-${index}`"
            >
              <v-divider v-if="index > 0" class="my-4 px-0"></v-divider>

              <div class="px-4">
                <div class="flex-1 mb-4">{{ label }}</div>
                <div class="flex justify-center space-x-16">
                  <div
                    v-for="{ label, value, labelClass } in choices"
                    :key="value"
                    class="w-16 text-center"
                    :class="{
                      'pointer-events-none': !isPatient,
                    }"
                  >
                    <img
                      class="cursor-pointer"
                      :src="
                        require(`.//../../assets/buttons/${value}${
                          value == $data.symptoms[prop] ? '_active' : ''
                        }.svg`)
                      "
                      @click="
                        (event) => {
                          onClickIconButton(event, prop, value);
                        }
                      "
                    />
                    <span :class="value == $data.symptoms[prop] ? labelClass : ''">
                      {{ label }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <!-- 運動 -->
      <v-card class="mx-2" elevation="0">
        <div class="p-4 bg-sky-50">
          <!-- ラベル・アイコン -->
          <div>
            <span class="text-xl tracking-widest text-sky-600"> 運動 </span>
            <div class="absolute top-2 left-2 rotate-12 opacity-10">
              <v-icon color="blue" size="90px"> mdi-run </v-icon>
            </div>
          </div>

          <!-- 運動 -->
          <validation-provider class="flex justify-center">
            <v-btn-toggle v-model="exercise" color="transparent">
              <v-btn
                v-for="{ value, label } in [
                  { value: 1, label: 'した' },
                  { value: 0, label: 'してない' },
                ]"
                :key="value"
                :value="value"
                :class="{ 'pointer-events-none': !isPatient }"
                height="80"
                width="120"
              >
                <span class="text-gray-800">{{ label }}</span>
                <v-icon v-if="exercise == value" color="teal" class="!absolute !text-7xl">
                  mdi-checkbox-blank-circle-outline
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </validation-provider>
        </div>
      </v-card>

      <!-- 保存ボタン -->
      <div class="sticky mt-12 px-2" style="bottom: 67px">
        <v-fade-transition>
          <v-btn
            v-show="dirty"
            color="teal"
            x-large
            block
            dark
            elevation="0"
            :disabled="invalid"
            @click="save"
          >
            <v-icon>mdi-check</v-icon>
            保存
          </v-btn>
        </v-fade-transition>
      </div>
    </v-container>
  </validation-observer>
</template>

<script>
import { doc, getDoc, getFirestore, serverTimestamp, setDoc } from "firebase/firestore";
import moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "Input",
  components: {},

  data: () => ({
    // 日付
    date: "",

    // 取得済みレコード
    records: {},

    // データベース
    db: null,

    // 体重
    weight: null,

    // 朝の記録
    morning: {
      // 最高血圧
      systolicPressure: null,

      // 最低血圧
      diastolicPressure: null,

      // 脈拍
      heartRate: null,
    },

    // 夜の記録
    night: {
      // 最高血圧
      systolicPressure: null,

      // 最低血圧
      diastolicPressure: null,

      // 脈拍
      heartRate: null,
    },

    // 服薬チェック
    medicationChecks: [],

    // 自覚症状
    symptoms: {
      // 息切れ
      shortnessOfBreath: null,

      // むくみ
      swelling: null,

      // 疲れやすさ
      tiredness: null,

      // 食欲低下
      lossOfAppetite: null,

      // 不眠
      insomnia: null,
    },

    // 自覚症状ラベル
    symptomsLabels: [
      {
        label: "息切れ",
        prop: "shortnessOfBreath",
        choices: [
          { label: "なし", value: 0, labelClass: "text-blue-500" },
          { label: "あり", value: 1, labelClass: "text-red-500" },
        ],
      },
      {
        label: "むくみ",
        prop: "swelling",
        choices: [
          { label: "なし", value: 0, labelClass: "text-blue-500" },
          { label: "あり", value: 1, labelClass: "text-red-500" },
        ],
      },
      {
        label: "疲れやすさ",
        prop: "tiredness",
        choices: [
          { label: "なし", value: 0, labelClass: "text-blue-500" },
          { label: "あり", value: 1, labelClass: "text-red-500" },
        ],
      },
      {
        label: "食欲低下",
        prop: "lossOfAppetite",
        choices: [
          { label: "なし", value: 0, labelClass: "text-blue-500" },
          { label: "あり", value: 1, labelClass: "text-red-500" },
        ],
      },
      {
        label: "不眠",
        prop: "insomnia",
        choices: [
          { label: "なし", value: 0, labelClass: "text-blue-500" },
          { label: "あり", value: 1, labelClass: "text-red-500" },
        ],
      },
    ],

    // 運動
    exercise: null,
  }),
  computed: {
    // 自身が患者かどうか
    isPatient() {
      return this.$store.state.user.selfUser?.type == "patient";
    },

    // 選択されている患者の UID
    selectedPatientUid() {
      return this.$store.state.user.selfUser?.uid;
    },

    // 日付フォーマット
    formatedDate() {
      const datetime = moment(this.date).startOf("day");
      const today = moment().startOf("day");

      switch (today.diff(datetime, "day")) {
        case 0:
          return datetime.format("今日");

        case 1:
          return "昨日";

        default:
          return datetime.format("M/D (ddd)");
      }
    },

    // 本日かどうか
    isToday() {
      return moment().isSame(moment(this.date), "day");
    },
  },
  watch: {
    // URL
    $route() {
      this.setDate();
    },

    // 日付
    date() {
      this.getRecord();
    },

    // 選択されている患者の UID
    selectedPatientUid() {
      this.getRecord();
    },
  },
  created() {
    // 患者以外で記録入力画面に遷移した場合はリダイレクト
    if (this.$store.state.user.selfUser && !this.isPatient) {
      this.$router.push("/list").catch(() => {});
      
      return;
    }

    this.db = getFirestore();
    this.setDate();
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),
    ...mapActions("snackbar", ["openSnackbar"]),

    // 記録を取得
    async getRecord() {
      if (!this.selectedPatientUid) {
        return;
      }

      // 取得済みレコードが存在する場合は、そちらからレコード取得
      if (
        Object.prototype.hasOwnProperty.call(this.$data.records, moment(this.date).format("YYYYMM"))
      ) {
        const monthRecord = this.$data.records[moment(this.date).format("YYYYMM")];
        if (Object.prototype.hasOwnProperty.call(monthRecord, moment(this.date).format("DD"))) {
          const record = monthRecord[moment(this.date).format("DD")];
          this.$data.exercise = record.exercise;
          this.$data.weight = record.weight;
          this.$data.morning = { ...record.morning };
          this.$data.night = { ...record.night };
          this.$data.medicationChecks = [...record.medicationChecks] ?? [];
          this.$data.symptoms = { ...record.symptoms };
        } else {
          this.initData();
        }
        return;
      }

      // 1月分のレコードをまとめて取得
      this.incrementRunningApiCount();

      const docSnap = await getDoc(
        doc(
          this.db,
          "users",
          this.selectedPatientUid,
          "records",
          moment(this.date).format("YYYYMM")
        )
      );

      this.decrementRunningApiCount();

      // 対象ドキュメントが存在しない場合、入力データを初期化
      if (!docSnap.exists()) {
        this.initData();
        return;
      } else {
        this.records[moment(this.date).format("YYYYMM")] = docSnap.data();
      }

      const key = moment(this.date).format("DD");

      // 対象日付のフィールドが存在しない場合、入力データを初期化
      if (!Object.keys(docSnap.data()).includes(moment(this.date).format("DD"))) {
        this.initData();
        return;
      }

      const {
        weight = null,
        morning,
        night,
        medicationChecks = [],
        symptoms = {},
        exercise = null,
      } = docSnap.data()[key];

      // 体重
      this.weight = weight;

      // 朝の血圧（脈拍）
      this.morning = {
        systolicPressure: morning?.systolicPressure ?? null,
        diastolicPressure: morning?.diastolicPressure ?? null,
        heartRate: morning?.heartRate ?? null,
      };

      // 夜の血圧（脈拍）
      this.night = {
        systolicPressure: night?.systolicPressure ?? null,
        diastolicPressure: night?.diastolicPressure ?? null,
        heartRate: night?.heartRate ?? null,
      };

      // 服薬チェック
      this.medicationChecks = medicationChecks;

      // 自覚症状
      const {
        shortnessOfBreath = null,
        swelling = null,
        tiredness = null,
        lossOfAppetite = null,
        insomnia = null,
      } = symptoms;

      this.symptoms = {
        // 息切れ
        shortnessOfBreath,

        // むくみ
        swelling,

        // 疲れやすさ
        tiredness,

        // 食欲低下
        lossOfAppetite,

        // 不眠
        insomnia,
      };

      // 運動
      this.exercise = exercise;
    },
    // 記録を保存
    async save() {
      this.incrementRunningApiCount();

      const recordRef = doc(
        this.db,
        "users",
        this.selectedPatientUid,
        "records",
        moment(this.date).format("YYYYMM")
      );

      // 既存レコードの場合は作成日・作成ユーザーを引き継ぎ
      let created = null;
      let createdUserid = null;
      if (
        Object.prototype.hasOwnProperty.call(this.$data.records, moment(this.date).format("YYYYMM"))
      ) {
        const monthRecord = this.$data.records[moment(this.date).format("YYYYMM")];
        if (Object.prototype.hasOwnProperty.call(monthRecord, moment(this.date).format("DD"))) {
          const record = monthRecord[moment(this.date).format("DD")];
          created = record.created;
          createdUserid = record.createdUserid;
        }
      }

      const { exercise = null, weight, medicationChecks, morning, night, symptoms } = this.$data;

      // 挿入レコード作成
      const record = {
        [moment(this.date).format("DD")]: {
          exercise,
          weight,
          medicationChecks,
          morning,
          night,
          symptoms,
          created: created ?? serverTimestamp(),
          createdUserid: createdUserid ?? this.selectedPatientUid,
          updated: serverTimestamp(),
          updatedUserid: this.selectedPatientUid,
        },
      };

      // TODO エラー時のスナックバー表示
      await setDoc(recordRef, record, { merge: true });
      this.$refs.observer.reset();

      // 既存データがなかった場合はオブジェクト自体を作成
      if (
        !Object.prototype.hasOwnProperty.call(
          this.$data.records,
          moment(this.date).format("YYYYMM")
        )
      ) {
        this.$data.records[moment(this.date).format("YYYYMM")] = {};
      }

      // 更新後データをメモリ上に保管
      this.$data.records[moment(this.date).format("YYYYMM")][moment(this.date).format("DD")] =
        record[moment(this.date).format("DD")];

      this.decrementRunningApiCount();

      this.openSnackbar({ text: "保存しました" });
    },
    // データの初期化処理
    initData() {
      this.$data.exercise = null;
      this.$data.weight = null;
      this.$data.morning = {
        diastolicPressure: null,
        systolicPressure: null,
        heartRate: null,
      };
      this.$data.night = {
        diastolicPressure: null,
        systolicPressure: null,
        heartRate: null,
      };
      this.$data.medicationChecks = [];
      this.$data.symptoms = {
        shortnessOfBreath: null,
        swelling: null,
        tiredness: null,
        lossOfAppetite: null,
        insomnia: null,
      };
    },
    // 日付を設定
    setDate() {
      const date = moment(this.$route.query.date);

      if (this.$refs.observer) {
        if (this.$refs.observer.dirty) {
          // TODO 画面に入力がある場合、ここで保存するかの確認が必要かも
        }
        this.$refs.observer.reset();
      }

      // 日付チェック
      if (!date.isValid) {
        alert("TODO: エラー画面に飛ばす");
        return;
      }

      // 未来日チェック
      if (date.isAfter(moment(), "day")) {
        alert("TODO: エラー画面に飛ばす");
        return;
      }

      // TODO: 過去日チェック（どこまで過去を許容するか要検討）

      // 年月日を設定
      this.date = date.format("YYYYMMDD");
    },

    // 前日をクリック
    onClickYesterday() {
      this.addDays(-1);
    },

    // 翌日をクリック
    onClickTomorrow() {
      this.addDays(1);
    },

    // 日付加算
    addDays(value) {
      this.$router
        .push({
          name: "/input",
          query: {
            date: moment(this.date).add(value, "days").format("YYYYMMDD"),
          },
        })
        .catch(() => {});
    },

    // 入力値をフォーマット
    formatedInputValue(value, integerDigits, decimalDigits) {
      // 数値チェック
      if (!value || isNaN(value) || value < 0) {
        return "";
      }

      // 整数部・小数部に部活
      const [integerPart, decimalPart = ""] = value.toString().split(".");

      // 整数部（桁数調整）
      const formattedInteger = integerPart.slice(-integerDigits);

      // 小数部（桁数調整・0埋め）
      const formattedDecimal = decimalDigits
        ? decimalPart.substring(0, decimalDigits).padEnd(decimalDigits, "0")
        : "";

      return decimalDigits ? `${formattedInteger}.${formattedDecimal}` : `${formattedInteger}`;
    },

    // 次の入力フォームをフォーカス
    focusNextElement(event) {
      event.preventDefault();

      const currentElement = event.target;

      // 入力フォームを全て取得
      const focusableElements = Array.from(document.querySelectorAll("input"));

      // 現在フォーカスされている入力フォームの要素
      const currentIndex = focusableElements.indexOf(currentElement);

      // 次の入力フォームをフォーカス
      if (currentIndex > -1 && currentIndex < focusableElements.length - 1) {
        focusableElements[currentIndex + 1].focus();
      }
    },

    // 服薬チェックが選択されているかどうか
    isSelectedMedicationCheck(value) {
      return this.medicationChecks.findIndex((medicationCheck) => medicationCheck == value) != -1;
    },

    // 自覚症状のアイコンボタンクリック
    onClickIconButton(event, prop, value) {
      // observerの状態をdirtyに
      this.$refs.observer.flags.dirty = true;

      const iconClassList = event.target.classList;
      iconClassList.remove("press");

      if (this[prop] == value) {
        this.symptoms[prop] = null;
      } else {
        this.symptoms[prop] = value;
        setTimeout(() => {
          iconClassList.add("press");
        }, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep input {
  @apply text-2xl;
  @apply text-end;
  @apply pr-2;
}

.press {
  animation: press 0.3s linear 1;
}

@keyframes press {
  0% {
    transform: scale(1);
  }
  55% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
</style>
