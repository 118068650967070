<template>
  <v-card class="p-4 space-y-4 text-center">
    <!-- タイトル -->
    <v-card-text v-if="title" class="px-0 text-h6"> {{ title }} </v-card-text>

    <!-- メッセージ -->
    <v-card-text v-if="message" class="px-0 whitespace-pre-line"> {{ message }} </v-card-text>

    <!-- 閉じるボタン -->
    <v-card-actions class="flex justify-center px-0 py-0">
      <v-btn class="flex" color="primary" outlined @click="$emit('close')"> 閉じる </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  // メッセージダイアログ
  name: "MessageDialog",
  props: {
    // タイトル
    title: {
      type: String,
    },

    // メッセージ
    message: {
      type: String,
    },
  },
};
</script>
