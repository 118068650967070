import { SNACKBAR_TIMEOUT } from "@/const/const";
import { mapActions } from "vuex";

export default function () {
  return {
    methods: {
      ...mapActions("api", ["resetRunningApiCount"]),
      ...mapActions("snackbar", ["openSnackbar"]),

      // エラー表示（スナックバー）
      showError(text, color = "error", timeout = SNACKBAR_TIMEOUT) {
        this.openSnackbar({
          text,
          color,
          timeout,
        });

        // 実行中の API数をリセット
        this.resetRunningApiCount();
      },
    },
  };
}
