<template>
  <div class="flex items-center justify-between">
    <!-- 先月へ -->
    <v-btn icon :disabled="disabledLastMonth || disabled" @click="onClickLastMonth">
      <v-icon color="primary" large> mdi-chevron-left </v-icon>
    </v-btn>

    <!-- 日付 -->
    <div class="text-center">{{ formatedDate }}</div>

    <!-- 翌月へ -->
    <v-btn icon :disabled="disabledNextMonth || disabled" @click="onClickNextMonth">
      <v-icon color="primary" large> mdi-chevron-right </v-icon>
    </v-btn>
  </div>
</template>

<script>
import moment from "moment";

export default {
  // 月選択
  name: "MonthPicker",
  props: {
    // 日付
    value: {
      default: "",
    },
    // 非活性
    disabled: {
      default: false,
    },
    // 選択できる月の上限
    maxMonth: {
      default: null,
    },
    // 選択できる月の下限
    minMonth: {
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    // 日付フォーマット
    formatedDate() {
      return `${moment(this.value).format("YYYY年 M月")}`;
    },
    // 先月ボタンを非活性
    disabledLastMonth() {
      return moment(this.value).format("YYYYMM") <= this.minMonth;
    },
    // 翌月ボタンを非活性
    disabledNextMonth() {
      return moment(this.value).format("YYYYMM") >= this.maxMonth;
    },
  },
  methods: {
    // 前月クリック
    onClickLastMonth() {
      this.$emit("input", moment(this.value).add(-1, "month").format("YYYYMMDD"));
    },
    // 翌月クリック
    onClickNextMonth() {
      this.$emit("input", moment(this.value).add(1, "month").format("YYYYMMDD"));
    },
  },
};
</script>

<style scoped></style>
