<template>
  <div class="flex items-center justify-between">
    <!-- 先週へ -->
    <v-btn icon :disabled="disabled" @click="onClickLastWeek">
      <v-icon color="primary" large> mdi-chevron-left </v-icon>
    </v-btn>

    <!-- 日付 -->
    <div>
      <div>{{ formatedFromDate }} ～</div>
      <div>{{ formatedToDate }}</div>
    </div>

    <!-- 翌週へ -->
    <v-btn icon :disabled="disabled || disabledNextWeek" @click="onClickNextWeek">
      <v-icon color="primary" large> mdi-chevron-right </v-icon>
    </v-btn>
  </div>
</template>

<script>
import moment from "moment";

export default {
  // 週選択
  name: "WeekPicker",
  mixins: [],
  props: {
    // 日付
    value: {
      default: "",
    },
    // 非活性化
    disabled: {
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(data) {
        this.$emit("input", data);
      },
    },

    // 週のはじめの日（月曜日）
    startOfWeekday() {
      return moment(this.value).startOf("week").weekday(1);
    },

    // 日付フォーマット（開始）
    formatedFromDate() {
      return `${this.startOfWeekday.format("YYYY年 MM月DD日（ddd）")}`;
    },
    // 日付フォーマット（終了）
    formatedToDate() {
      return `${this.startOfWeekday.add(6, "days").format("YYYY年 MM月DD日（ddd）")}`;
    },

    // 翌週ボタンの非活性状態
    disabledNextWeek() {
      return moment(this.value)
        .startOf("week")
        .weekday(1)
        .isSameOrAfter(moment().startOf("week").weekday(1));
    },
  },
  created() {
    this.$emit("input", this.startOfWeekday.format("YYYY-MM-DD"));
  },
  methods: {
    // 先週ボタンクリック
    onClickLastWeek() {
      this.$emit("input", moment(this.value).add(-7, "days").format("YYYY-MM-DD"));
    },

    // 翌週ボタンクリック
    onClickNextWeek() {
      this.$emit("input", moment(this.value).add(7, "days").format("YYYY-MM-DD"));
    },
  },
};
</script>

<style scoped></style>
