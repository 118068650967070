<template>
  <v-container class="p-0">
    <!-- 日付 -->
    <div v-show="false" class="px-4 mb-4 flex justify-between items-center">
      <!-- 前日へ -->
      <v-btn icon @click="onClickYesterday">
        <v-icon color="primary" large> mdi-chevron-left </v-icon>
      </v-btn>

      <!-- 日付 -->
      <div>
        {{ formatedDate }}
      </div>

      <!-- 翌日へ -->
      <v-btn icon :disabled="isThisMonth" @click="onClickTomorrow">
        <v-icon color="primary" large> mdi-chevron-right </v-icon>
      </v-btn>
    </div>

    <!-- 体重 -->
    <div v-show="false" class="flex justify-between items-center">
      <div class="px-2">体重</div>
      <div>
        <!-- <v-btn-toggle v-model="sport">
            <v-btn
              v-for="(label, index) in ['週', '月']"
              :key="index"
              class="w-20"
              :color="sport == index ? 'primary' : ''"
              x-large
            >
              <span :class="sport == index ? 'text-white' : ''">{{
                label
              }}</span>
            </v-btn>
          </v-btn-toggle> -->
      </div>
    </div>
    <!-- <weight-graph :date="date"></weight-graph>
    <v-divider class="my-4 px-0"></v-divider> -->

    <!-- 朝の血圧 -->
    <!-- <div class="flex justify-between items-center">
      <div class="px-2">朝の血圧</div>
    </div>
    <v-divider class="my-4 px-0"></v-divider> -->

    <!-- 夜の血圧 -->
    <!-- <div class="flex justify-between items-center">
      <div class="px-2">夜の血圧</div>
    </div>
    <blood-pressure-graph :date="date"></blood-pressure-graph> -->

    <div>
      <!-- タブ -->
      <v-tabs v-model="selectedTab" fixed-tabs>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="tab in tabs" :key="tab">
          {{ tab }}
        </v-tab>
      </v-tabs>

      <!-- グラフ -->
      <v-tabs-items v-model="selectedTab" touchless>
        <!-- 体重 -->
        <v-tab-item :key="tabs[0]" :transition="false">
          <!-- 日付 -->
          <div class="px-4 mt-4 flex justify-between items-center">
            <!-- 月選択 -->
            <month-picker v-model="date" :disabled="loadingWeightGraph"></month-picker>
          </div>

          <!-- グラフ -->
          <weight-graph :date="date" @update:loading="setLoadingWeightGraph"></weight-graph>
        </v-tab-item>

        <!-- 血圧（脈拍） -->
        <v-tab-item :key="tabs[1]" :transition="false">
          <!-- 日付 -->
          <div class="px-4 mt-4">
            <week-picker
              v-model="bloodPressureGraphDate"
              :disabled="loadingBloodPressure"
            ></week-picker>
          </div>

          <!-- グラフ -->
          <blood-pressure-graph
            :date="bloodPressureGraphDate"
            @update:loading="setLoadingBloodPressure"
          ></blood-pressure-graph>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import WeightGraph from "@/components/organisms/WeightGraph";
import BloodPressureGraph from "@/components/organisms/BloodPressureGraph";
import MonthPicker from "@/components/molecules/MonthPicker";
import WeekPicker from "@/components/molecules/WeekPicker";
export default {
  name: "Graph",
  components: { WeightGraph, BloodPressureGraph, MonthPicker, WeekPicker },

  data: () => ({
    // タブ
    selectedTab: 0,
    tabs: ["体重", "血圧"],

    // 日付
    date: moment().format("YYYYMMDD"),
    bloodPressureGraphDate: moment().format("YYYYMMDD"),

    // 体重グラフの読み込み状態
    loadingWeightGraph: true,

    // 血圧グラフの読み込み状態
    loadingBloodPressure: true,
  }),
  computed: {
    formatedDate() {
      return moment(this.date).format("YYYY年 M月");
    },

    isThisMonth() {
      return (
        moment().isSame(this.moment(this.date), "year") &&
        moment().isSame(this.moment(this.date), "month")
      );
    },
  },
  watch: {},
  created() {},
  methods: {
    onClickYesterday() {
      this.date = moment(this.date).add(-1, "month").format("YYYYMMDD");
    },

    onClickToday() {
      this.$router
        .push({
          name: "/input",
          query: {
            date: moment().format("YYYYMMDD"),
          },
        })
        .catch(() => {});
    },

    onClickTomorrow() {
      this.date = moment(this.date).add(1, "month").format("YYYYMMDD");
    },
    // 体重グラフの読み込み状態を設定
    setLoadingWeightGraph(loading) {
      this.loadingWeightGraph = loading;
    },
    // 血圧グラフの読み込み状態を設定
    setLoadingBloodPressure(loading) {
      this.loadingBloodPressure = loading;
    },
  },
};
</script>

<style lang="scss" scoped></style>
