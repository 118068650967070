<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-container class="h-full flex justify-center items-center">
      <div class="flex flex-col">
        <!-- メッセージ -->
        <div class="mb-4">
          <div>登録したメールアドレスを入力してください。</div>
          <div>パスワード再設定画面のURLをメールでお送りします。</div>
        </div>

        <!-- メールアドレス -->
        <div>
          <validation-provider name="メールアドレス" rules="required|email">
            <v-text-field
              v-model="email"
              label="メールアドレス"
              type="email"
              prepend-inner-icon="mdi-email-outline"
              outlined
              dense
            ></v-text-field>
          </validation-provider>
        </div>

        <!-- 送信ボタン -->
        <v-btn
          color="primary"
          elevation="0"
          block
          :disabled="invalid"
          @click="sendPasswordResettingMail"
        >
          送信
        </v-btn>
      </div>
    </v-container>
  </validation-observer>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default {
  name: "PasswordResetting",
  components: {},

  data: () => ({
    // メールアドレス
    email: "",
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    // パスワード再設定メール送信
    sendPasswordResettingMail() {
      sendPasswordResetEmail(getAuth(), this.email);
    },
  },
};
</script>

<style lang="scss" scoped></style>
