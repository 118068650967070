<template>
  <v-card class="p-4 space-y-4 text-center">
    <!-- タイトル -->
    <v-card-text v-if="title" class="px-0 text-h6"> {{ title }} </v-card-text>

    <!-- メッセージ -->
    <v-card-text v-if="message" class="px-0 whitespace-pre-line"> {{ message }} </v-card-text>

    <v-card-actions class="flex justify-center px-0 py-0">
      <!-- キャンセルボタン -->
      <v-btn class="flex-1" color="grey" outlined @click="$emit('cancel')"> キャンセル </v-btn>

      <!-- OKボタン -->
      <v-btn class="flex-1" :color="okButtonColor" dark elevation="0" @click="$emit('ok')">
        {{ okButtonLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  // 確認ダイアログ
  name: "ConfirmDialog",
  props: {
    // タイトル
    title: {
      type: String,
    },

    // メッセージ
    message: {
      type: String,
    },

    // OKボタンラベル
    okButtonLabel: {
      default: "OK",
      type: String,
    },

    // OKボタン色
    okButtonColor: {
      default: "primary",
      type: String,
    },
  },
};
</script>
