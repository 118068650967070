<template>
  <v-container ref="message-container" class="h-full py-0">
    <!-- メッセージ -->
    <div class="pt-2 space-y-4" style="height: calc(100% - 73px)">
      <!-- もっとメッセージを読み込む -->
      <div v-if="messages.length > 0 && !isLoadedAllMessage" class="text-center">
        <v-btn color="primary" text @click="loadPastMessages"> もっとメッセージを読み込む </v-btn>
      </div>

      <!-- メッセージ -->
      <div
        v-for="({ id, uid, message, datetime, deleted }, index) in messages"
        :key="id"
        class="gap-4"
      >
        <!-- 日付（区切り）ラベル -->
        <div v-if="checkDateChanged(index)" class="mb-2 text-center">
          <v-chip class="px-4" color="primary" small>{{ getDividerDateLabel(datetime) }}</v-chip>
        </div>

        <div class="flex gap-4" :class="{ 'justify-end': isMe(uid) }">
          <!-- アイコン -->
          <v-avatar v-if="!isMe(uid)" class="mt-2" size="40">
            <v-img :src="require(`.//../../assets/logo.svg`)"> </v-img>
          </v-avatar>

          <!-- ユーザ名・投稿日時・メッセージ -->
          <div class="relative">
            <div
              class="absolute whitespace-nowrap text-sm text-primary"
              :class="{ 'end-0': isMe(uid) }"
            >
              <!-- ユーザー名 -->
              <span v-if="!isMe(uid)" class="mr-1">
                {{ userNames[uid] || "削除されたユーザー" }}
              </span>

              <!-- 投稿日時 -->
              <span class="text-xs text-gray-400">
                {{ formatDatetime(datetime) }}
              </span>

              <!-- 既読数 -->
              <span v-if="isMe(uid) && !deleted" class="ml-2 text-xs text-gray-400">
                既読 {{ getReadCount(datetime) }}
              </span>

              <!-- 削除ボタン -->
              <v-btn v-if="isMe(uid) && !deleted" x-small icon @click="onClickDelete(index)">
                <v-icon color="grey" small>mdi-delete</v-icon>
              </v-btn>
            </div>

            <!-- メッセージ -->
            <div
              class="mt-6 px-4 py-3 rounded-lg whitespace-pre-line break-all"
              :class="{
                'l-comment': !isMe(uid),
                'bg-blue-100': isMe(uid) && !deleted,
                'bg-gray-100 text-gray-400': deleted,
              }"
            >
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新しいメッセージ -->
    <div v-show="!isScrolledToBottom && hasNewMessage" class="sticky" style="bottom: 128px">
      <div class="w-full text-center">
        <v-btn icon @click="scrollTo()">
          <v-icon class="bg-white" color="primary" size="40"> mdi-arrow-down-bold-circle </v-icon>
        </v-btn>
      </div>
    </div>

    <!-- メッセージ入力・送信 -->
    <validation-provider v-slot="{ errors }" rules="max:500">
      <div class="sticky mt-4 py-2 bg-white" style="bottom: 56px">
        <div class="flex items-center space-x-2">
          <!-- メッセージ入力 -->
          <v-textarea
            v-model="inputMessage"
            rows="1"
            auto-grow
            outlined
            dense
            hide-details
            :error-messages="errors"
          >
          </v-textarea>

          <!-- 送信ボタン -->
          <v-btn
            color="primary"
            height="40"
            elevation="0"
            :disabled="!inputMessage || errors?.length > 0"
            @click="postMessage"
          >
            送信
          </v-btn>
        </div>
      </div>
    </validation-provider>

    <!-- メッセージ削除確認ダイアログ -->
    <v-dialog v-model="messageDeleteConfirmDialog.show" max-width="480">
      <confirm-dialog
        v-if="messageDeleteConfirmDialog.show"
        title="メッセージを削除しますか？"
        message="削除したメッセージは元に戻せません"
        okButtonLabel="削除"
        okButtonColor="red darken-1"
        @ok="deleteMessage"
        @cancel="messageDeleteConfirmDialog.show = false"
      ></confirm-dialog>
    </v-dialog>

    <!-- 削除完了ダイアログ -->
    <v-dialog v-model="deleteCompleteDialog.show" max-width="400">
      <message-dialog
        v-if="deleteCompleteDialog.show"
        title="メッセージを削除しました"
        @close="deleteCompleteDialog.show = false"
      ></message-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import Confirm from "@/components/dialogs/Confirm";
import Message from "@/components/dialogs/Message";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
} from "firebase/firestore";
import moment from "moment";
import { mapActions } from "vuex";

// メッセージの読み込み量（初回）
const INITIAL_LOAD_MESSAGE_COUNT = 20;

// メッセージの読み込み量（過去）
const PAST_LOAD_MESSAGE_COUNT = 10;

export default {
  name: "Chat",
  components: { ConfirmDialog: Confirm, MessageDialog: Message },
  props: {
    groupId: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    // メッセージコンテナ
    messageContainer: null,

    // メッセージ
    messages: [],

    // 入力メッセージ
    inputMessage: "",

    // 最下部までスクロールされたか
    isScrolledToBottom: true,

    // 自動スクロール
    autoScroll: false,

    // 過去のメッセージを全て読み込んだか
    isLoadedAllMessage: false,

    // 新しいメッセージの有無
    hasNewMessage: false,

    // UID
    uid: null,

    // データベース
    db: null,

    // 一番上のドキュメント（メッセージ）
    topDoc: null,

    // 一番下のドキュメント（メッセージ）
    bottomDoc: null,

    // メッセージの購読解除
    unsubscribe: null,

    // 最終読み取り日時
    lastReadDatetimes: {},

    // 最終読み取り日時の購読解除
    unsubscribeLastReadDatetimes: null,

    // 削除ダイアログ
    messageDeleteConfirmDialog: {
      show: false,
      messageIndex: null,
    },

    // 削除完了ダイアログ
    deleteCompleteDialog: {
      show: false,
    },
  }),
  computed: {
    // ユーザー名（自身 + 家族 + 医師）
    userNames() {
      const userNames = {};
      const { uid, firstName, lastName } = this.$store.state.user.selfUser;
      userNames[uid] = `${lastName} ${firstName}`;

      this.$store.state.user.families.forEach(({ uid, firstName, lastName }) => {
        userNames[uid] = `${lastName} ${firstName}`;
      });

      this.$store.state.user.doctors.forEach(({ uid, firstName, lastName }) => {
        userNames[uid] = `${lastName} ${firstName}`;
      });

      return userNames;
    },
  },
  mounted() {
    // スクロールイベントを登録
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    // UID を取得しメッセージを読み込む
    const auth = getAuth();

    this.uid = auth.currentUser.uid;

    // メッセージ読み込み（初回）
    this.db = getFirestore();
    this.loadInitialMessages();

    //アイコンバッジ更新
    this.badgeCounterUpdate();

    // 通知削除
    this.resetNotification();

    // 最終読み取り日時を更新
    this.updateLastReadDatetimes();

    // 最終読み取り日時の購読
    this.unsubscribeLastReadDatetimes = this.subscribeLastReadDatetimes();
  },
  destroyed() {
    // スクロールイベントを破棄
    window.removeEventListener("scroll", this.handleScroll);

    // メッセージの購読解除
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    // 最終読み取り日時の購読解除
    if (this.unsubscribeLastReadDatetimes) {
      this.unsubscribeLastReadDatetimes();
    }
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // スクロールイベント
    handleScroll() {
      // 最下部までスクロールされたか判定
      const scrollTop = Math.ceil(window.scrollY);
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = window.innerHeight;
      this.isScrolledToBottom = scrollTop + clientHeight >= scrollHeight;

      // 最下部までスクロールされた場合は新しいメッセージの有無を非表示
      if (this.isScrolledToBottom) {
        this.hasNewMessage = false;
      }
    },

    // メッセージ読み込み（初回）
    async loadInitialMessages() {
      this.incrementRunningApiCount();

      const _query = query(
        collection(this.db, "chatGroups", this.groupId, "messages"),
        limit(INITIAL_LOAD_MESSAGE_COUNT),
        orderBy("datetime", "desc")
      );

      const documentSnapshots = await getDocs(_query);
      const { docs = [] } = documentSnapshots;

      docs.forEach((doc) => {
        this.messages.unshift({ id: doc.id, ...doc.data() });
      });

      if (docs.length > 0) {
        this.topDoc = docs[docs.length - 1];
        this.bottomDoc = docs[0];
      }

      if (INITIAL_LOAD_MESSAGE_COUNT > docs.length) {
        this.isLoadedAllMessage = true;
      }

      // 最下部までスクロール
      this.scrollTo();

      // メッセージ購読を開始
      this.unsubscribe = this.subscribeMessage();

      this.decrementRunningApiCount();
    },

    // メッセージ購読
    subscribeMessage() {
      const _query = query(
        collection(this.db, "chatGroups", this.groupId, "messages"),
        orderBy("datetime", "asc"),
        startAfter(this.bottomDoc)
      );

      return onSnapshot(_query, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const doc = change.doc;

          switch (change.type) {
            // メッセージ追加
            case "added":
              this.messages.push({
                id: doc.id,
                ...doc.data({ serverTimestamps: "estimate" }),
              });

              // スクロールバーが最下部の場合は最下部までスクロール
              if (this.isScrolledToBottom) {
                this.scrollTo();
              } else {
                // スクロール途中かつ、他人のメッセージを取得した際は新着メッセージの有無を表示
                if (!this.isMe(doc.data().uid)) {
                  this.hasNewMessage = true;
                }
              }

              // 最終読み取り日時を更新
              this.updateLastReadDatetimes();

              // 通知を削除
              this.resetNotification();

              break;
          }
        });
      });
    },

    // 過去メッセージ読み込み
    async loadPastMessages() {
      this.incrementRunningApiCount();

      const _query = query(
        collection(this.db, "chatGroups", this.groupId, "messages"),
        limit(PAST_LOAD_MESSAGE_COUNT),
        orderBy("datetime", "desc"),
        startAfter(this.topDoc)
      );

      // スクロール位置をキープするため、読み込み前のコンテナの高さを保持しておく
      const beforeScrollHeight = document.documentElement.scrollHeight;

      const documentSnapshots = await getDocs(_query);
      const { docs = [] } = documentSnapshots;

      docs.forEach((doc) => {
        this.messages.unshift({ id: doc.id, ...doc.data() });
      });

      if (docs.length > 0) {
        this.topDoc = docs[docs.length - 1];

        // スクロール位置を復元
        this.$nextTick(() => {
          this.scrollTo(document.documentElement.scrollHeight - beforeScrollHeight);
        });
      }

      if (PAST_LOAD_MESSAGE_COUNT > docs.length) {
        this.isLoadedAllMessage = true;
      }

      this.decrementRunningApiCount();
    },

    // 指定された位置までスクロール（位置の指定がない場合は最下部までスクロール）
    scrollTo(top) {
      this.autoScroll = true;

      this.$nextTick(() => {
        window.scrollTo({
          top: top ? top : document.documentElement.scrollHeight,
          behavior: "auto",
        });

        setTimeout(() => {
          this.autoScroll = false;
        }, 500);
      });
    },

    // 最終読み取り日時を更新
    async updateLastReadDatetimes() {
      await setDoc(
        doc(collection(this.db, "chatGroups", this.groupId, "lastReadDatetimes"), this.uid),
        {
          datetime: serverTimestamp(),
        }
      );
    },

    // 最終読み取り日時の購読
    // TODO: App.vue でも最終読み取り日時を購読しているため、watch で store を監視できないか要検討
    subscribeLastReadDatetimes() {
      const _query = query(collection(this.db, "chatGroups", this.groupId, "lastReadDatetimes"));

      return onSnapshot(_query, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          // 最終読み取り日時を設定
          const doc = change.doc;
          const { datetime } = doc.data();

          if (datetime) {
            this.lastReadDatetimes[doc.id] = datetime.seconds;

            // 自身のメッセージを再設定することで既読数を更新
            this.messages.forEach((message, index) => {
              if (this.isMe(message.uid)) {
                this.messages.splice(index, 1, message);
              }
            });
          }
        });
      });
    },

    // UID が自身かどうかを判定
    isMe(uid) {
      return uid === this.uid;
    },

    checkDateChanged(index) {
      // 過去のメッセージを全て読み込んだ場合
      if (this.isLoadedAllMessage && index == 0) {
        return true;
      }

      if (index == 0) {
        return false;
      }

      return !moment
        .unix(this.messages[index].datetime.seconds)
        .isSame(moment.unix(this.messages[index - 1].datetime.seconds), "day");
    },

    // 日付（区切り）ラベル取得
    getDividerDateLabel(value) {
      const datetime = moment.unix(value?.seconds).startOf("day");
      const today = moment().startOf("day");

      switch (today.diff(datetime, "day")) {
        case 0:
          return "今日";

        case 1:
          return "昨日";

        default:
          return datetime.format("M/D (ddd)");
      }
    },

    // 日時フォーマット
    formatDatetime(value) {
      return moment.unix(value?.seconds).format("HH:mm");
    },

    // 既読数を取得
    getReadCount(datetime) {
      // 自身以外の最終読み取り日時から既読数を計算
      let readCount = 0;
      Object.keys(this.lastReadDatetimes).forEach((key) => {
        if (!this.isMe(key) && this.lastReadDatetimes[key] >= datetime.seconds) {
          readCount++;
        }
      });
      return readCount;
    },

    // メッセージ送信
    async postMessage() {
      await addDoc(collection(this.db, "chatGroups", this.groupId, "messages"), {
        uid: this.uid,
        message: this.inputMessage,
        datetime: serverTimestamp(),
      });

      // メッセージをクリア
      this.inputMessage = "";

      // 最下部までスクロール
      this.hasNewMessage = false;
      this.scrollTo();
    },

    // 削除ボタン押下
    onClickDelete(index) {
      this.messageDeleteConfirmDialog.show = true;
      this.messageDeleteConfirmDialog.messageIndex = index;
    },

    // メッセージを削除
    deleteMessage() {
      try {
        // メッセージを削除
        const { messageIndex } = this.messageDeleteConfirmDialog;

        deleteDoc(
          doc(this.db, "chatGroups", this.groupId, "messages", this.messages[messageIndex].id)
        );

        this.messages[messageIndex].deleted = true;
        this.messages[messageIndex].message = "削除済み";

        // 削除完了ダイアログを表示
        this.deleteCompleteDialog.show = true;
      } catch {
        // TODO: エラーハンドリング
        alert("メッセージの削除に失敗しました");
      } finally {
        // メッセージ削除確認ダイアログを閉じる
        this.messageDeleteConfirmDialog.show = false;
        this.messageDeleteConfirmDialog.messageIndex = null;
      }
    },

    // アイコンバッジを更新する（nativeアプリのみ）
    async badgeCounterUpdate() {
      if (window.navigator.userAgent.indexOf("WV_app") != -1) {
        const chatGroup = this.$store.state.chat.chatGroups.find(
          (chatGroup) => chatGroup.id == this.groupId
        );
        let unreadMessageCount = chatGroup.unreadMessageCount;
        // アイコンバッジカウンターから、既読になる未読件数分を引く
        await window.flutter_inappwebview.callHandler(
          "badgeCounterUpdate",
          -1 * unreadMessageCount
        );
      }
    },

    async resetNotification() {
      if (window.navigator.userAgent.indexOf("WV_app") != -1) {
        await window.flutter_inappwebview.callHandler(
          "resetNotificationByChatGroupId",
          this.groupId
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* 吹き出しデザイン */
.l-comment {
  position: relative;
  background: rgb(241 245 249);
}

.l-comment:after {
  content: "";
  position: absolute;
  top: 3px;
  left: -19px;
  border: 8px solid transparent;
  border-right: 18px solid rgb(241 245 249);
  transform: rotate(35deg);
  -webkit-transform: rotate(35deg);
}
</style>
