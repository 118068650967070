import { doc, getDoc, getFirestore } from "firebase/firestore";
import { mapActions } from "vuex";

export default function () {
  return {
    methods: {
      ...mapActions("user", ["setSelfUser"]),

      // 自身のユーザー（関連する家族・患者情報を含む）情報を取得
      async getSelfUserAndRelatedUser(uid) {
        const db = getFirestore();
        const docSnap = await getDoc(doc(db, "users", uid));

        if (docSnap.exists()) {
          // Store に設定
          this.setSelfUser({ uid, selfUser: docSnap.data() });
        } else {
          // TODO: エラーハンドリング
          alert("ユーザー情報が存在しません");
        }
      },
    },
  };
}
