import { SNACKBAR_TIMEOUT } from "@/const/const";

const getters = {};

const state = {
  // スナックバーを表示するか
  snackbar: false,

  // スナックバーに表示するテキスト
  text: "",

  // スナックバーの色
  color: "",

  // スナックバーが自動で閉じるまでの時間
  timeout: SNACKBAR_TIMEOUT,
};

const mutations = {
  // スナックバーを表示
  openSnackbar: (state, { text, color = "success", timeout = SNACKBAR_TIMEOUT }) => {
    state.snackbar = true;
    state.text = text;
    state.color = color;
    state.timeout = timeout;
  },

  // スナックバーを閉じる
  closeSnackbar: () => {
    state.snackbar = false;
    state.text = "";
  },
};

const actions = {
  openSnackbar: ({ commit }, props) => {
    commit("openSnackbar", props);
  },
  closeSnackbar: ({ commit }) => {
    commit("closeSnackbar");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
